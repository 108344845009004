import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { load as loadOnfido } from 'financeit-components/utils/load-onfido'

export default class OnfidoWebSdkComponent extends Component {
  @service intl
  @service router

  sdkLocale = this.intl.primaryLocale.startsWith('fr') ? 'fr_CA' : 'en_US'
  crossDeviceClientIntroProductLogoSrc = this.args.isBigBox ?
    `https://www.financeit.ca/assets/fi_public/project_loan/onfido/logo-${this.intl.language}.png` : 'https://www.financeit.ca/assets/financeit-logo.svg'
  crossDeviceSessionLinkedTitleKey = this.args.isBigBox ? 'home_depot' : 'financeit'
  onfidoSdkToken = this.args.onfidoForm.onfido_sdk_token
  onfidoSdkSteps = this.args.onfidoForm.onfido_sdk_steps

  didInsert(_, [thisComponent]) {
    // FYI, this function is not a lifecycle event in Glimmer components but rather a function
    // that is called by the did-insert modifier in the hbs file, with the component context
    // passed in as `thisComponent`
    loadOnfido().then(() => {
      thisComponent.onfidoSDKInit()
    }).catch((error) => {
      thisComponent.router.transitionTo('error').then(() => {
        throw new Error(`Onfido SDK failed to load: ${error?.srcElement?.outerHTML}`)
      })
    })
  }

  onfidoSDKInit() {
    const sdkOptions = {
      token: this.onfidoSdkToken,
      containerId: 'onfido-mount',
      steps: this.onfidoSdkSteps,
      smsNumberCountryCode: 'CA',
      onComplete: (callbackData) => {
        this.args.createOnfidoCheck(callbackData)
      },
      crossDeviceClientIntroProductLogoSrc: this.crossDeviceClientIntroProductLogoSrc,
      language: {
        locale: this.sdkLocale,
        phrases: {
          cross_device_session_linked: {
            title: this.intl.t(`onfido-web-sdk.cross_device_session_linked.${this.crossDeviceSessionLinkedTitleKey}.title`),
            subtitle: this.intl.t('onfido-web-sdk.cross_device_session_linked.subtitle'),
            info: this.intl.t('onfido-web-sdk.cross_device_session_linked.info'),
            list_item_sent_by_you: this.intl.t('onfido-web-sdk.cross_device_session_linked.list_item_sent_by_you'),
            list_item_desktop_open: this.intl.t('onfido-web-sdk.cross_device_session_linked.list_item_desktop_open'),
          }
        },
      }
    }
    this.onfidoSDK = window.Onfido.init(sdkOptions)
  }

  willDestroy() {
    this.onfidoSDK?.tearDown()
    super.willDestroy(...arguments)
  }
}
